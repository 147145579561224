import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  scales,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AreaGraph = (props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: props.showLegend || false,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      y: {
        beginAtZero: props.startFromZero || false,
      },
    },
  };

  const datasets = props.values.map((item, i) => {
    return {
      fill: true,
      label: item.label || "",
      data: item.value,
      borderColor: item.color || "#3C50E0",
      borderWidth: 2,
      backgroundColor: item.color ? `${item.color}44` : "#3C50E044",
      tension: 0.2,
    };
  });
  const data = {
    labels: props.labels,
    datasets: datasets,
  };
  return (
    <Line
      height={"90%"}
      options={options}
      data={data}
      className="max-h-[280px]"
    />
  );
};

export default AreaGraph;
